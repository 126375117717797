import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as qs from 'qs';
import { Container, Grid } from '@material-ui/core';
import LandingTemplate from './shared/LandingTemplate';
import LoginForm from '../components/login/LoginForm';
import Loader from '../components/loader/Loader';
import Toaster from '../components/alerts/Toaster';
import { useTranslation } from 'react-i18next';
import '../styles/pages/login.scss';
import { BUSINESS_ORIGINATION_URL, ONBOARDING_BUSINESS_ORIGINATION_URL, getEnv } from '../env';
import OfferPage from './OfferPage';

import { riskAnalysisFolderService } from '../services/riskAnalysisFolder.service';

export const LoginPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [showInputs, setShowInputs] = useState(true);
    const [toast, setToast] = useState(null);
    const [processInstanceId, setProcessInstanceId] = useState(null);
    const [customerData, setCustomerData] = useState(null);
    const [businessKey, setBusinessKey] = useState(null);
    const history = useHistory();
    const { t } = useTranslation();

    const [offer, setOffer] = useState(null);

    const [marketingData, setMarketingData] = useState({});

    const restart = async () => {
        setOffer(null);
        setShowInputs(true);
    }

    const evaluateRiskAnalysisFolder = async (customerInfo) => {
        try {
            
            setLoading(true);

                setCustomerData(customerInfo);

                const payload = {
                        customerId: customerInfo.idNumber,
                        customerTaxId: customerInfo.taxId,
                        customerFullName: customerInfo.customerFullName,
                        customerFirstName: customerInfo.customerFirstName ? customerInfo.customerFirstName : customerInfo.customerFullName.split(' ')[1],
                        customerLastName: customerInfo.customerLastName ? customerInfo.customerLastName : customerInfo.customerFullName.split(' ')[0],
                        customerBirthdate: customerInfo.birthday ? (customerInfo.birthday.getFullYear() + "-" + (customerInfo.birthday.getMonth() < 9 ? "0" : "") + (customerInfo.birthday.getMonth()+1) + "-" + (customerInfo.birthday.getDate() < 10 ? "0" : "") + customerInfo.birthday.getDate()) : undefined,
                        customerEmailAddress: customerInfo.email,
                        customerCellphoneNumber: customerInfo.cellphone,
                        financialProductType: [
                            "CREDIT_CARD"
                        ],
                        businessOriginationUrl: `${getEnv(BUSINESS_ORIGINATION_URL)}`,
                        onboardingBusinessOriginationUrl: `${getEnv(ONBOARDING_BUSINESS_ORIGINATION_URL)}`,
                        variablesData: {
                            promoterCode: "DIRECTV"
                        },
                };
                
                const offerResponse = (await riskAnalysisFolderService.evaluate(payload)).data;

                if(offerResponse.status === "APPROVED") {
                    setOffer(offerResponse);
                    setShowInputs(false);
                } else {
                    history.push(`/noRetryable/REJECTED`);
                }
                
        } catch (error) {
            console.log(error);
            if (error.response) {
                const { cause, code } = error.response.data;
                if (error.response.data.code === 409) {
                    setToast({ message: t(`WORKFLOW_ERROR.${error.response.data.errorCode}.DESCRIPTION`) });
                } else if (error.response.data.code === 401 && error.response.data.errorCode === 'UNAUTHORIZED') {    
                    setToast({ message: t(`WORKFLOW_ERROR.${error.response.data.cause}.DESCRIPTION`) });
                } else {
                    setToast({ message: t(`WORKFLOW_ERROR.UNKNOWN_ERROR.DESCRIPTION`) });
                }
            }
            localStorage.clear();
        } finally {
            setLoading(false);
        }
    };

    return (
        <LandingTemplate {...props}>
            <Loader loading={loading} />
            <Toaster toast={toast} />
            {showInputs && (
                <Container maxWidth="sm">
                    <Grid container>
                        <Grid item xs={false} sm={1}></Grid>
                        <Grid item xs={12} sm={10} container>
                            <LoginForm onSubmit={evaluateRiskAnalysisFolder} disabled={loading}></LoginForm>
                        </Grid>
                        <Grid item xs={false} sm={1}></Grid>
                    </Grid>
                </Container>
            )}
            {offer && customerData && (
                <Container maxWidth="sm">
                    <Grid container>
                        <Grid item xs={false} sm={1}></Grid>
                        <Grid item xs={12} sm={10} container>
                            <OfferPage offer={offer} customerInfo={customerData} onRestart={restart} disabled={loading}></OfferPage>
                        </Grid>
                        <Grid item xs={false} sm={1}></Grid>
                    </Grid>
                </Container>
            )}

        </LandingTemplate>
    );
};
