import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Grid, Hidden, DialogTitle, Dialog, DialogContent, Typography, DialogActions, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask';
import { useTranslation } from 'react-i18next';
import Loader from '../loader/Loader';
import { CheckEmail } from '../checkEmail/checkEmail';
import afipService from '../../services/afip.service';
import Toaster from '../alerts/Toaster';
import HelpIcon from '@material-ui/icons/Help';
import '../../styles/components/tabs.scss';
import { obfuscate } from '../obfuscate/obfuscate';
import { CellphoneTextField } from './CellphoneTextField';
import { CustomDatePicker } from '../customInputs/CustomDatePicker';

const DniMaskCustom = (props) => {
    const { inputRef, ...other } = props;
    return <MaskedInput {...other} mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} placeholderChar={'\u2000'} />;
};

DniMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const CuitMaskCustom = (props) => {
    const { inputRef, ...other } = props;
    return <MaskedInput {...other} mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} placeholderChar={'\u2000'} />;
};

CuitMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
    title: {
        margin: theme.spacing(3, 0, 0, 0),
        textAlign: 'left',
    },
    subtitle: {
        margin: theme.spacing(1, 5, 1, 0),
        textAlign: 'left',
    },
    continueButton: {
        margin: theme.spacing(5, 0, 0, 0),
    },
    textField: {
        margin: theme.spacing(2, 0, 0, 0),
    },
    resendCode: {
        margin: theme.spacing(3, 0, 0, 0),
    },
}));

const MAX_WAIT_TIME = 60;

export default function LoginForm(props) {
    const classes = useStyles();
    const { onSubmit, disabled } = props;
    const ref = useRef();
    const { t } = useTranslation();
    // state
    const [status, setStatus] = useState('typing');
    const [customerInfo, setCustomerInfo] = useState({
        idNumber: null,
        taxId: null,
        email: null,
        cellphone: null,
    });
    const [taxIdComboData, setTaxIdComboData] = useState([]);
    const [toast, setToast] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [mockedPin, setMockedPin] = useState(null);
    const [seconds, setSeconds] = useState(MAX_WAIT_TIME);
    const [smsRetryIntervalId, setSmsRetryIntervalId] = useState(null);
    const [manualCuitError, setManualCuitError] = useState(false);
    const [showAlternativeCuit, setShowAlternativeCuit] = useState(false);
    const [manualCuit, setManualCuit] = useState('');
    const [manualFirstName, setManualFirstName] = useState('');
    const [manualLastName, setManualLastName] = useState('');
    const [birthDate, setBirthDate] = useState('');

    useEffect(() => {
        return () => clearInterval(smsRetryIntervalId);
    }, []);

    const handleCellphoneChange = (number, valid) => {
        if (valid) {
            setCustomerInfo({ ...customerInfo, cellphone: number, cellphonePin: null });
        } else {
            setCustomerInfo({ ...customerInfo, cellphone: null, cellphonePin: null });
        }
    };

    const handleClickOpenPopup = () => {
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleChangeEmail = (email, valid) => {
        if (valid) {
            setCustomerInfo({ ...customerInfo, email: email });
        } else {
            setCustomerInfo({ ...customerInfo, email: null });
        }
    };

    const handleCustomerIdClick = async () => {
        if (!customerInfo.idNumber || customerInfo.idNumber.length < 7) {
            return;
        }
        try {
            setStatus('loading');
            const taxIdComboData = (await afipService.getCuits(customerInfo.idNumber)).data;
            if (taxIdComboData && taxIdComboData.length === 1) {
                setCustomerInfo({ ...customerInfo, taxId: taxIdComboData[0].value, customerFullName: taxIdComboData[0].label });
            }
            setTaxIdComboData(taxIdComboData);
        } catch (error) {
            //setShowAlternativeCuit(true);
            const errorMessage = 'No pudimos obtener la información del cliente. Intentá nuevamente.';

            setToast({ message: errorMessage });
        } finally {
            setStatus('typing');
        }
    };

    const isSubmitButtonDisabled = () => {
        if (disabled) {
            return true;
        }
            return showAlternativeCuit ? (!customerInfo.email || !customerInfo.cellphone || !customerInfo.taxId || !customerInfo.customerFirstName || !customerInfo.customerLastName || !customerInfo.birthday) : (!customerInfo.email || !customerInfo.cellphone || !customerInfo.taxId);
    };

    const handleTaxIdComboItemChange = (e) => {
        setCustomerInfo({ ...customerInfo, taxId: e.target.id });
    };

    const handleFormSubmitted = (event) => {
        event.preventDefault();
       onSubmit(customerInfo);
    };

    const handleCustomerIdChange = (e) => {
        setCustomerInfo({
            ...customerInfo,
            idNumber: e.target.value.trim(),
            taxId: null,
        });
        setTaxIdComboData([]);
    };

    const handleManualCuitChange = (e) => {
        const cuit = e.target.value.trim();
        setManualCuitError(false);
        setManualCuit(cuit);
        setCustomerInfo({
            ...customerInfo,
            taxId: cuit.replaceAll(/-/g, ''),
        });
    };

    const handleFirstNameChange = (e) => {
        setManualFirstName(e.target.value);
        setCustomerInfo({
            ...customerInfo,
            customerFirstName: e.target.value,
        });
    };

    const handleLastNameChange = (e) => {
        setManualLastName(e.target.value);
        setCustomerInfo({
            ...customerInfo,
            customerLastName: e.target.value,
        });
    };

    const handleDateChange = (e) => {
        if (e == 'Invalid Date' || e.length < 10 || e.toString().includes("_")) {
            setBirthDate(null);
            setCustomerInfo({
                ...customerInfo,
                birthday: null,
            });
        } else {
            setBirthDate(e);
            setCustomerInfo({
                ...customerInfo,
                birthday: e,
            });
        }
    };

    const onStateChange = () => {
        if (seconds <= 0) {
            clearInterval(smsRetryIntervalId);
            setSmsRetryIntervalId(null);
            setSeconds(MAX_WAIT_TIME);
        }
    };

    onStateChange();

    const selectManualCustomerTaxId = (
        <>
            <div>
                <Grid container direction="row" justifyContent="flex-start" className="">
                    <Grid item xs={12} sm={12} lg={12} className="mt-4">

                        <TextField
                            id="standard-basic"
                            variant="outlined"
                            label="Ingresá tu CUIT sin puntos ni espacios"
                            fullWidth={true}
                            className={classes.textField}
                            color="secondary"
                            style={{ textAlign: 'left' }}
                            name="manualCuit"
                            onChange={handleManualCuitChange}
                            value={manualCuit}
                            error={manualCuitError}
                            helperText={manualCuitError && 'Debés ingresar un CUIT válido'}
                            InputProps={{
                                inputComponent: CuitMaskCustom,
                            }}
                            required="true"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} className="mt-4">
                        <div className="mt-2"></div>
                        <TextField
                            id="standard-basic"
                            label="Ingresá tu nombre"
                            fullWidth={true}
                            className={classes.textField}
                            color="secondary"
                            style={{ textAlign: 'left' }}
                            name="manualFirstName"
                            onChange={handleFirstNameChange}
                            value={manualFirstName}
                            required={true}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} className="mt-4">
                        <div className="mt-2"></div>
                        <TextField
                            id="standard-basic"
                            label="Ingresá tu apellido"
                            fullWidth={true}
                            className={classes.textField}
                            color="secondary"
                            style={{ textAlign: 'left' }}
                            name="manualLastName"
                            onChange={handleLastNameChange}
                            value={manualLastName}
                            variant="outlined"
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} lg={8} className="mt-4">
                        <div className="mt-2"></div>
                        <CustomDatePicker
                            style={{ marginTop: '0%', width: '100%' }}
                            //format="dd/MM/yyyy"
                            margin="normal"
                            id="datePicker"
                            label="Fecha de nacimiento"
                            value={birthDate}
                            errorHelperText="Completá la fecha con el formato dd/mm/aaaa"
                            onChange={(date) => handleDateChange(date)}
                            required={true}
                            variant="outlined"
                            fullWidth={true}
                            className={classes.textField}
                            color="secondary"
                            KeyboardButtonProps={{
                                'aria-label': 'Fecha',
                            }}></CustomDatePicker>
                    </Grid>
                </Grid>
            </div>
        
        </>
    );

    const selectCustomerTaxId = (
        <>
            <div className="cuitDescription mt-1">
                <span className="fs-14 color-darker-gray">
                    <div className="select-cuit-desc-title">
                        <span>¿Cuál de estas personas sos? </span>
                        <Button variant="text" onClick={handleClickOpenPopup} color="default">
                            <HelpIcon style={{ zIndex: 999, height: '30px', fontSize: 24, color: 'dark-gray' }} />
                        </Button>
                    </div>
                    <Dialog onClose={handleClosePopup} aria-labelledby="help-dialog-title" open={openPopup}>
                        <DialogTitle id="help-dialog-title" onClose={handleClosePopup}>
                            Ayuda
                        </DialogTitle>
                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Encontramos más de una coincidencia para este DNI. Por seguridad, los nombres aparecen ofuscados.
                            </Typography>
                            <Typography>Ejemplo:</Typography>
                            <Typography style={{ fontWeight: 'bold' }}>Perez García Juan Pablo se verá como PER** ***** **** **BLO</Typography>
                            <Typography gutterBottom>Por favor, seleccioná quién sos para continuar.</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={handleClosePopup} color="primary" style={{ width: '100%' }}>
                                Entendido
                            </Button>
                        </DialogActions>
                    </Dialog>
                </span>
                {taxIdComboData.map((comboItem) => (
                    <div key={comboItem.value} value={comboItem.value}>
                        <div className="separator mt-05 mb-05"></div>
                        <div className="flex">
                            <input
                                onChange={handleTaxIdComboItemChange}
                                type="radio"
                                id={comboItem.value}
                                name="taxId"
                                value={(comboItem.value, comboItem.label)}
                            />
                            <div className="ml-03-per">
                                <label htmlFor={comboItem.value} className="fw-400 fs-15 color-black">
                                    {obfuscate(comboItem.label)}
                                </label>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );

    return (
        <>
                <Grid container justifyContent="center">
                    <Grid item>
                        <h1 className={classes.title}>Tarjeta de crédito</h1>
                        <h1 >Macro 100% online</h1>

                        <h3 className={classes.subtitle}>Ingresá los datos para conocer la tarjeta disponible para el cliente</h3>
                    </Grid>
                </Grid>
            
            <Grid container>
                <Loader loading={status === 'loading'} />
                <Grid item container direction="row" justifyContent="flex-start">
                    <Toaster toast={toast} />
                    <Grid item xs={12}>
                        <form onSubmit={handleFormSubmitted}>
                            
                                
                                    <Grid container direction="row" align="center" className="mt-2">
                                        <Grid item xs={12} sm={12} lg={12}>
                                            <TextField
                                                autoFocus
                                                variant="outlined"
                                                label="DNI"
                                                required={true}
                                                fullWidth={true}
                                                color="secondary"
                                                name="idNumber"
                                                disabled={status === 'loading' || disabled}
                                                onChange={handleCustomerIdChange}
                                                onBlur={handleCustomerIdClick}
                                                value={customerInfo.idNumber}
                                                className={classes.textField}
                                                InputProps={{
                                                    inputComponent: DniMaskCustom,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    {taxIdComboData.length > 1 && selectCustomerTaxId}
                                    {taxIdComboData.length < 1 && showAlternativeCuit && selectManualCustomerTaxId}
                                    <CheckEmail
                                        mail={customerInfo.email}
                                        loading={status === 'loading'}
                                        onChange={handleChangeEmail}
                                    />
                                    <CellphoneTextField loading={status === 'loading'} onChange={handleCellphoneChange} />
                                
                            

                            

                            <Grid container justifyContent="center">
                                <Button
                                    variant="contained"
                                    disableElevation={true}
                                    fullWidth={true}
                                    color="primary"
                                    type="submit"
                                    className={classes.continueButton}
                                    disabled={status === 'loading' || isSubmitButtonDisabled()}>
                                    Siguiente
                                </Button>
                            </Grid>
                            
                        </form>
                    </Grid>
                    <Hidden xsDown>
                        <Grid item sm={1}></Grid>
                    </Hidden>
                </Grid>
            </Grid>
        </>
    );
}
